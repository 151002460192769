import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { authenticatedTaxRequest } from "../util";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { HOSTNAME } from "../constants";

const TaxVerificationDialog = ({ isOpen, handleClose, user }) => {
  const [requestDetails, setRequestDetails] = React.useState();
  const [requestStatus, setRequestStatus] = React.useState();
  const [requestReason, setRequestReason] = React.useState();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Request Tax Documents</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A time sensitive link will be sent to your email address that will
          allow you to download your tax documents. Proceed?
          {requestStatus === "ok" && (
            <Alert severity="success">{requestDetails}</Alert>
          )}
          {requestStatus === "error" && (
            <>
              {requestReason === "throttle" && (
                <Alert severity="error">{requestDetails}</Alert>
              )}
              {requestReason !== "throttle" && (
                <Alert severity="error">
                  An error has occurred while generating a link to your tax
                  documents. Please try again later or contact
                  info@daedalusfund.com if this continues to occur.
                </Alert>
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {!requestDetails && (
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              const res = await authenticatedTaxRequest(
                `${HOSTNAME}/v2/tax/link`,
                user
              );

              if (res.reason === "throttle") {
                setRequestDetails(
                  "You've requested your tax documents too many times. Please try again tomorrow."
                );
              } else {
                setRequestDetails(
                  "An email containing a link to your tax documents was successfully sent. Please also check spam if you are unable to find the email in your regular inbox after 20 minutes."
                );
              }

              setRequestStatus(res.status);
              setRequestReason(res.reason);
            }}
          >
            Yes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TaxVerificationDialog;
